@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.SideMenu {
  background: linear-gradient(to right, rebeccapurple, #005b9a); 
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  animation: zoomIn 0.3s ease forwards; 
 
  max-width: 100%;
  width: 100%;
  height: 100vh; 
  padding: 20px;
  z-index: 100;
  display:flex;
  align-items:center; 
  justify-content: center;


}


.SideMenu .verticalMenu .nav-link {
  position: relative;
  
  padding: 10px;
  color: white;
  font-family: "Hanken Grotesk", sans-serif;
  font-weight:900;
  font-size:40px;
  text-align: center;
  transition: transform 0.2s ease; 
 

}
  .SideMenu .verticalMenu .nav-link:hover {
    color: blueviolet;
    transform: translateY(-6px); 
  

  }

  .SideMenu .iconify {
    width: 32px;
    height: 32px;
    transition: transform 0.2s ease; /* Adding transition for smooth animation */

  }
  
  .SideMenu .iconify:hover {
    transform: translateY(-6px); /* Pushing the icon up by 5px on hover */
  }
  








