.footer {
  padding: 50px 0;
  margin-top: 120px;
}

.footer h1{
  color: white;
  font-weight:900;
  font-size:30px;
 

}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}



.footer-heading  {
  font-size: 25px;
  margin-bottom: 20px;
  margin-right: 30px;
 
}



.footer .nav-link {
  color: white;
  margin-bottom: 10px;
  text-transform:capitalize;
  font-family:"Hanken Grotesk", sans-serif;
  font-size:15px;
  font-weight:500;
  line-height: 15px;
  

  


}

.footer .nav-link {
  transition: transform 0.2s ease;
}

.footer .nav-link:hover {
  transform: translateY(-6px);
  color: blueviolet;
}
.qrcode {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  
}

.footer .qrcode img{
  width: 50%;
  height:50%;
}
/* icons animation */
.footer .iconify {
  width: 28px;
  height: 28px;
  transition: transform 0.2s ease; /* Adding transition for smooth animation */
}

.footer .iconify:hover {
  transform: translateY(-6px); /* Pushing the icon up by 5px on hover */
}
