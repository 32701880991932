.Navbar {
  background: linear-gradient(to right, rebeccapurple, #005b9a);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
 
}
.Navbar .navbar-brand {
  color: white;
  font-family:"Hanken Grotesk", sans-serif;
  font-weight:900;
  font-size:30px;
  letter-spacing: 2px;
}
.Navbar .navbar-brand:hover{
 color:blueviolet; ;
}
.Navbar .navbar-brand img{

  width: 15%;
  box-shadow: 2px red;
 

}
.custom-duration {
  --animate-duration: 3s; /* Change the duration to your desired value */
}


@media screen and (max-width: 768px) {
  .Navbar .navbar-brand {
    font-size: 23px; /* Adjust the font size for smaller screens */

  }
  .Navbar .navbar-brand img{

    width: 20%;
   
  
  }
  
}



