body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, rebeccapurple, #005b9a);
color: white;
}

h1, p ,i {
  font-family: "Hanken Grotesk", sans-serif;
}

h1{
  text-align: center;
  text-transform:uppercase;
  font-size:40px;
  font-weight:900;
  letter-spacing: 2px;
 
}
/* button */
 .btn{
  background-color: #f5f5f5; /* Light gray */
  color: rebeccapurple;
  padding: 10px 15px 10px 10px;
  font-family: "Hanken Grotesk", sans-serif;;
  font-size:20px;
  font-weight:900;
  width: 200px;
  letter-spacing: 1px;
}
.btn:hover{
  background-color:blueviolet;
  border: 2px solid white;
  color:white;
}
