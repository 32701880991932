.Info{
 
 margin-top: 50px;

  
}
.Info h1{
  
  text-align: center;
  text-transform:uppercase;
  font-family: "Hanken Grotesk", sans-serif;
  font-size:40px;
  font-weight:900;
}


@media screen and (max-width: 768px) {
  .Info h1 {
    font-size: 31px; /* Adjust the font size for smaller screens */
  }

}

.Info .Intro p{
  text-transform:uppercase;
  font-family: "Hanken Grotesk", sans-serif;
  font-size:12px;
  font-weight:400;
 
  line-height: 27px;
 
  

}
.Info .Intro p span{
color: #005b9a;
font-size: 18px;
}
.Info img {
  width: 100%;
  height: 100%;
  border-radius: 5%;
}
.InfoButton {
  display: flex;
  justify-content: center;
  padding: 40px;
  
}


