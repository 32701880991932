.SellingBoxes {
  margin-top: 70px;
  
  
}

.SellingBoxes p{

  text-transform:capitalize;
  font-family:"Hanken Grotesk", sans-serif;
  font-size:18px;
  font-weight:500;
  line-height: 30px;
  display: flex;
justify-content: center;
  text-align: center;

}
/* cards */

 .SellingBoxes .card{
  background: linear-gradient(to right, rebeccapurple, #005b9a);
 
 border: 2px solid white;
    margin-bottom: 0px;
   height: 100%;
    margin-top: 20px;
    border-radius: 5%;
    
   
  
  }
  .SellingBoxes .card img{
    height: 100%;
    width: 100%;

  }
  
    .SellingBoxes  .card-body{
      margin-top: 10px;
    
     ;
    }
    .SellingBoxes  text{
      font-family:"Hanken Grotesk", sans-serif;
      font-weight:900;
      font-size:20px;
      color:white;
      
    }

    .SellingBoxes  .card-text{
      width: 100%;
      color:white;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      height: 120px;
    }
  


.card-zoom {
  overflow: hidden; }

.card-zoom img, .img-hover, .gray-hover {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease; }

.card-zoom img:hover, .img-hover:hover {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: translateZ(0) scale(1.1);
  transform: translateZ(0) scale(1.1);
  z-index: 1000;
  position: relative; }


  .SellingBoxes .iconify {
    align-items: left;
   
    width: 25px;
    height: 25px;
    transition: transform 0.2s ease; /* Adding transition for smooth animation */
  }
  
  .SellingBoxes .iconify:hover {
    transform: translateY(-6px); /* Pushing the icon up by 5px on hover */
  }
