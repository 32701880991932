.HeroContainer{
  padding: 0px;
  width: 100%;
  

}  

.heroHeading{
  font-family: "Hanken Grotesk", sans-serif;
  font-size:58px;
  font-weight:900;
  width:70%;
  white-space: nowrap; 
  margin-bottom: 0%;
  line-height: 80px;
  margin-top: 120px;}
  
  i{
   
    
    border-bottom: 2px solid white;
    
  }


 .HeroImage {
margin-top: 120px;
  height: 100%;
  width: 100%;

}
.imgHero{
  width: 100%;
  border-radius: 5%;
  

}
  
span{
  display: block;
}



@media screen and (max-width: 768px) {
  .heroHeading {
  margin-top: 20px;
    font-size: 40px; 
   
    
  }
}
@media screen and (max-width:  991px) {
  .heroHeading  {
 position: relative;
 top: 80px;
    line-height: 50px;
    
  }
}


