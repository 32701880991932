.Loading-Container {
  background-color:black;
  max-width: 100%;
  width: 100%;
  height: 100vh; /* Set the container to cover the entire viewport height */
  display:flex;
  align-items:center; /* Center vertically */
  justify-content: center;
}

.Loading-number {
  color:white; /* Assuming you want the number to be white on the black background */
  font-family: "Roboto", sans-serif;;
  font-weight:900;
  font-size:70px;
}