.sectionWithBackground {
  margin-top: 120px;

  
}

.sectionWithBackground .row > :nth-child(2) {

 display: flex;
 justify-content: center;
}
.sectionWithBackground .row > :nth-child(3) {
 
  display: flex;
  justify-content: end;
 }



.sectionWithBackground .iconHead{

  margin-top: 50px;
}

.sectionWithBackground .iconify {
  margin-right: 10px;
  width: 28px;
  height: 28px;
 
}
@media screen and (max-width: 768px) {
  .sectionWithBackground .row > :nth-child(2) {
    display: flex;
  justify-content: start;
  margin: 10px 0  20px 0;
   
  }
}
@media screen and (max-width: 768px) {
  .sectionWithBackground .row > :nth-child(3) {
    display: flex;
  justify-content: start;
  margin: 10px 0  20px 0;
   
  }
}

