.language-switcher {
  display: flex;
}

.language-switcher button {
  background: linear-gradient(to right, rebeccapurple, #005b9a);
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-size: 15px;
  font-family: "Hanken Grotesk", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}

.language-switcher button:last-child {
  margin-right: 0;
}

.language-switcher button:hover {
  background-color:blueviolet;
}
.language-switcher .dropdown-item:hover {
  background-color: blueviolet;
  color:white
  
}
.language-switcher .dropdown-item.active {
  background: linear-gradient(to right, rebeccapurple, #005b9a);
 
}
.language-switcher .dropdown-menu {
  background-color: white;
}
.language-switcher #dropdown-basic-button{
  background-color: transparent;

  }
  .language-switcher .dropdown-item{
    font-family: "Hanken Grotesk", sans-serif;
    font-weight:600;
    font-size:14px;
    
  }




